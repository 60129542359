<!--
 * @Author: LiZhiWei
 * @Date: 2024-07-29 11:40:27
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-12-06 15:27:54
 * @Descripttion: 
-->
<template>
  <ClientOnly>
    <el-skeleton :rows="5" :loading="showData.length === 0" animated class="pt-20px" />
    <div ref="viewContain" class="view-contain">
      <div
        ref="showContain"
        :class="{ 'data-contain': true }"
        @mouseenter="pause = true"
        @mouseleave="pause = false"
      >
        <el-row
          v-for="i in showData"
          :key="i.id"
          class="flex-y-center h-40px justify-between w-full font-s-14px"
        >
          <el-col :span="15">
            <MLink :to="`/credit/post/${i.id}`">
              <div class="overflow-hidden text-ellipsis w-full whitespace-nowrap">
                <i class="i-custom-circle wh-8px mr-15px"></i>
                {{ i.name }}
              </div>
            </MLink>
          </el-col>
          <el-col :span="5" class="overflow-hidden text-ellipsis w-full whitespace-nowrap">
            <!-- <span class="lh-14px text-color-3">
              <i v-if="i.districtCode" class="i-custom-location wh-11px"></i>
              {{ getArea(i.districtCode) }}
            </span> -->
          </el-col>
          <el-col :span="4" class="flex justify-end">
            <span class="color-primary font-s-16px w-2rem">{{ i.creditLevel }}</span>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- <el-empty v-else :image-size="80" description="暂无相关内容"></el-empty> -->
  </ClientOnly>
</template>
<script setup lang="ts">
  import { districts } from '~/constants'

  const props = defineProps({
    list: {
      type: Array,
      default: () => [],
    },
  })

  const apiData = ref<any[]>([])
  const viewContain = ref<HTMLDivElement | null>(null)
  const showContain = ref<HTMLDivElement | null>(null)
  const startIndex = ref<number>(0)
  const endIndex = ref<number>(0)
  const pause = ref(false)
  const showData = ref<any[]>([])
  const setShowData = () => {
    endIndex.value = startIndex.value + 6
    if (endIndex.value <= apiData.value.length - 1) {
      showData.value = apiData.value.slice(startIndex.value, endIndex.value)
    } else {
      showData.value = apiData.value
        .slice(startIndex.value)
        .concat(apiData.value.slice(0, endIndex.value - (apiData.value.length - 1)))
    }
  }

  const districtList = ref(districts)

  const getArea = (districtCode = '') => {
    let area = districtList.value
      .map((item) => item.id !== '')
      .find((item) => item.id === districtCode)
    return area?.name
  }

  const onInfinite = () => {
    let start = null
    // 当前的起始索引
    let frameStartIdx = 0
    function step(timestamp) {
      if (pause.value) {
        requestAnimationFrame(step)
        return
      }
      if (start === null) {
        start = timestamp
      }
      const elapsed = timestamp - start
      // elapsed为起始帧和当前帧差值，由于我们想的是每2s滚动过一条，因此每1ms运动的距离为0.02px
      const count = Math.min(0.02 * elapsed, 40)
      startIndex.value = frameStartIdx
      if (showContain.value && showContain.value.style) {
        showContain.value.style.top = `-${count}px`
        if (count === 40) {
          // 当运动了40px后，重置我们的showContain位置
          showContain.value.style.top = '0'
          if (frameStartIdx === apiData.value.length - 1) {
            // 起始帧数大于原数据最大索引后重置
            frameStartIdx = 0
          } else {
            frameStartIdx += 1
          }
          startIndex.value = frameStartIdx
          setShowData()
          start = null
        }
      } // 递归调用，实现无限滚动
      requestAnimationFrame(step)
    }
    requestAnimationFrame(step)
  }

  watch(
    () => props.list,
    (newValue) => {
      apiData.value = [...newValue]
    },
    {
      immediate: true,
    }
  )

  onMounted(() => {
    onInfinite()
  })
</script>

<style scoped lang="scss">
  .view-contain {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .data-contain {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      &-item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
